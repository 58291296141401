import React, { useContext } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, Input, SmallLoader, Spinner, TopLayer } from 'components';
import { FileDrop } from 'react-file-drop';
import { ToastTypes, useToast } from 'context/toast';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputBox from 'components/common/formInput';
import { getFileExtension, getSignedUrl, uploadFile } from 'utils/uploadFile';
import uuid from 'react-uuid';
import { schemaForMedia } from 'validations/contentFormValidation';
import { TODO_ANY } from 'typings/common';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import SrtUpload from '../uploadSrt';
import { UploadedTo } from '../utils';
import { saveAs } from 'file-saver';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import { convertToKey, removeExtension, upperCaseFirstLetter } from 'pages/Content/utils';
import LanguageDropdown from 'components/LanguageDropdown';
const initialDefaultValues = {
  url: '',
  alternativeText: '',
  fileName: '',
};

const imageFormat = ['jpeg', 'png', 'jpg'];
const videoFormat = ['m4v', 'avi', 'mpg', 'mp4'];
const AddMedia = () => {
  const { id } = useParams();
  const { authUser } = useAuth();
  const inputRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isdownloading, setIsDownloading] = useState<boolean>(false);
  const [fileType, setFileType] = useState<any>('all');
  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [isModalAction, setIsModalAction] = useState(false);
  const [err, setErr] = useState<boolean>(true);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState<any>('');
  const [fileTitle, setFileTitle] = useState<any>('');
  const [defaultValues, setDefaultValues] = useState<any>(initialDefaultValues);
  const [fileUrl, setFileUrl] = useState<any>('');
  const navigate = useNavigate();
  const [srtValue, setSrtValue] = useState<any>({});
  const { callToast } = useToast();
  const { t }: any = useTranslation();
  const { langLists, setLangLists } = useContext(LanguageListsContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);
  const [videoSrtKey, setVideoSrtKey] = useState<any>('');
  const isEditPage = useMemo(() => !!id, [id]);
  const [uploadedTo, setUploadedTo] = useState<any>('');
  const pageTitle = useMemo(
    () =>
      isEditPage
        ? t('edit_media', currentSelected?.langCode || selectedLanguage?.langCode) || 'Edit Media'
        : t('add_media', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add Media',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const titleButtonText = useMemo(
    () =>
      isEditPage
        ? t('save', currentSelected?.langCode || selectedLanguage?.langCode) || 'Save'
        : t('add', currentSelected?.langCode || selectedLanguage?.langCode) || 'Add',
    [isEditPage, currentSelected?.langCode || selectedLanguage?.langCode],
  );
  const {
    unregister,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    shouldUnregister: true,
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaForMedia),
  });
  const handleContentList = useCallback(() => {
    navigate('/media');
  }, [navigate]);
  const fileHandler = async (e: any, event: any) => {
    let maxSize = 0;
    event.preventDefault();
    const extension = e[0]?.name.split('.')[e[0]?.name.split('.').length - 1]?.toLowerCase();

    if (imageFormat.includes(extension) || videoFormat.includes(extension)) {
      let file = e[0];
      if (imageFormat.includes(extension)) {
        setFileType('image');
        maxSize = 20 * 1024 * 1024;
        let fileName = `${+new Date()}`;
        const blob = file.slice(0, file.size, file.type);
        file = new File([blob], fileName, { type: file.type });
      }
      if (videoFormat.includes(extension)) {
        setFileType('video');
        maxSize = 50 * 1024 * 1024;
      }
      setFileSize(file?.size);
      setFileTitle(file?.name.split('.')[0]);
      const imageSize = file.size;

      if (maxSize < imageSize) {
        callToast(
          ToastTypes.ERROR,
          t('image_size_exceed', currentSelected?.langCode || selectedLanguage?.langCode) || 'Image size exceed',
        );
        return;
      }
      setIsImageUploading(true);
      const reader = new FileReader();
      reader.readAsDataURL(e?.[0]);
      reader.onloadend = () => {
        setFileUrl(reader?.result);
      };
      try {
        const key = videoSrtKey
          ? `${videoSrtKey}.${getFileExtension(file.name)}`
          : `media/content/${uuid()}/${Date.now()}.${getFileExtension(file.name)}`;
        const signedUrlData = await getSignedUrl(file, key);

        setVideoSrtKey(key.split('.')[0]);
        await uploadFile(file, signedUrlData?.data?.result?.url);
        setErr(false);
        setValue('url', `${key}`);
        clearErrors('url');
        setValue('fileName', file?.name.split('.')[0]);
        setIsImageUploading(false);
      } catch (e: any) {
        setIsImageUploading(false);
        callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
      }
    } else {
      callToast(
        ToastTypes.ERROR,
        t('file_type_not_support', currentSelected?.langCode || selectedLanguage?.langCode) ||
          'File Type Not Supported',
      );
    }
  };
  const addMedia = async (data: any) => {
    try {
      setLoading(true);
      const res = await postAPIData({
        url: 'media',
        data: {
          ...data,
          createdBy: authUser.id,
          updatedBy: authUser.id,
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
      setLoading(false);

      if (isModalAction) {
        selectedData(currentSelectedLangId);
        setIsModalAction(false);
        navigate(`/media/edit/${id ?? res?.data?.id}`);
      } else {
        navigate('/media');
      }

      callToast(
        ToastTypes.SUCCESS,
        t('media_added', currentSelected?.langCode || selectedLanguage?.langCode) || 'Media added successfully',
      );
    } catch (e: any) {
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(e?.response?.data?.message[0]) || e?.message);
      setLoading(false);
    }
  };
  const editMedia = async (data: any) => {
    data = { ...data, languageId: currentSelected?.id || selectedLanguage?.id };
    if (data?.srt?.mediaLang) {
      data.srt = {
        url: data?.srt?.url,
        type: data?.srt?.type,
        fileName: data?.srt?.fileName,
        fileSize: data?.srt?.fileSize,
      };
    }
    try {
      setLoading(true);
      const res = await putAPIData({
        url: `media/${id}`,
        data: data,
      });
      setLoading(false);
      if (isModalAction) {
        selectedData(currentSelectedLangId);
        setIsModalAction(false);
        navigate(`/media/edit/${id ?? res?.data?.id}`);
      } else {
        navigate('/media');
      }

      callToast(
        ToastTypes.SUCCESS,
        t('media_updated', currentSelected?.langCode || selectedLanguage?.langCode) || 'Media updated successfully',
      );
    } catch (e: any) {
      callToast(ToastTypes.ERROR, upperCaseFirstLetter(e?.response?.data?.message) || e?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (authUser && Object.keys(authUser)?.length) {
      initialData();
    }
  }, [currentSelected?.id || selectedLanguage?.id]);
  const initialData = async () => {
    setLoading(true);
    if (isEditPage) {
      await getMediaById();
    }
    setLoading(false);
  };

  const getMediaById = async () => {
    try {
      const res = await getAPIData({
        url: `media/${id}`,
        data: {
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
      setFileType(res?.data?.type);
      setFileSize(res?.data?.fileSize);
      setFileTitle(res?.data?.fileName);
      setFileUrl(res?.data?.url);
      setValue('url', res?.data?.url);
      setErr(false);
      setValue(
        'alternativeText',
        res?.data?.mediaLang?.filter((e: any) => e?.languageId == (currentSelected?.id || selectedLanguage?.id))?.[0]
          ?.alternativeText || '',
      );
      setUploadedTo(UploadedTo(res?.data));
      setValue('fileName', res?.data?.fileName);
      if (res?.data?.type == 'video' && res?.data?.srtVideo) {
        let key = res?.data?.srtVideo.url.replace(/\.[^/.]+$/, '');
        setSrtValue(res?.data?.srtVideo);
        setVideoSrtKey(removeExtension(res?.data?.url));
      } else {
        setVideoSrtKey(removeExtension(res?.data?.url));
      }
    } catch (e: any) {
      setLoading(false);
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
  };
  const removeURL = (data: TODO_ANY) => {
    if (data?.url?.split('.net/')?.length > 1) data.url = data?.url?.split('.net/')?.[1];
    return data;
  };
  const getFormData = (data: TODO_ANY) => {
    if (fileType == 'video' && Object.entries(srtValue).length > 0) {
      data = { ...data, fileSize, type: fileType, srt: srtValue };
    } else {
      data = { ...data, fileSize, type: fileType };
    }
    if (data?.url) {
      data = removeURL(data);
    }
    if (!isEditPage) {
      addMedia(data);
    } else {
      editMedia(data);
    }
  };
  const RemoveFile = (bool: boolean) => {
    setVideoSrtKey('');
    setFileTitle('');
    setFileSize('');
    setFileUrl('');
    setSrtValue({});
    setErr(true);
    setValue('url', '');
    setValue('fileName', '');
    if (!bool) {
      setError('url', {
        type: 'manual',
        message: 'This field is required',
      });
    }
  };
  const filePicker = () => {
    inputRef.current.click();
  };
  const downloadFile = async () => {
    callToast(
      ToastTypes.INFO,
      t('download_started', currentSelected?.langCode || selectedLanguage?.langCode) || 'Download Started',
    );
    setIsDownloading(true);
    try {
      let downloadResult;
      if (fileType == 'video') {
        downloadResult = await fetch(`${fileUrl}`, { mode: 'cors' });
      } else {
        downloadResult = await fetch(`${fileUrl}`);
      }
      if (downloadResult.ok) {
        const blob = await downloadResult.blob();
        if (fileType == 'image') {
          saveAs(blob, `${fileTitle}.png`);
        } else {
          saveAs(blob, `${fileTitle}.mp4`);
        }
        callToast(
          ToastTypes.SUCCESS,
          t('download_success', currentSelected?.langCode || selectedLanguage?.langCode) || 'Downloaded Successfully',
        );
      } else {
        callToast(
          ToastTypes.ERROR,
          t('something_went_wrong', currentSelected?.langCode || selectedLanguage?.langCode) || 'Something went wrong',
        );
      }
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    }
    setIsDownloading(false);
  };
  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };
  return (
    <section className="MainContainerAddMedia">
      <div className="flex justify-between">
        <TopLayer
          onButtonClicked={handleSubmit(getFormData)}
          buttonText={t(titleButtonText, currentSelected?.langCode || selectedLanguage?.langCode) || titleButtonText}
          titleText={t(pageTitle, currentSelected?.langCode || selectedLanguage?.langCode) || pageTitle}
          className="mb-8"
          onCancelClicked={handleContentList}
          disableBtn={loading || isImageUploading}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
        <LanguageDropdown
          langLists={langLists}
          value={currentSelected?.langCode || selectedLanguage?.langCode}
          onChange={(e: any) => {
            setCurrentSelectedLangId(e);
            setResetConfirmation(true);
            setIsModalAction(true);
          }}
        />
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset(initialDefaultValues);
            RemoveFile(true);
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
            setIsModalAction(false);
          }}
          deleteTitle={'How would you like to proceed '}
          button3="Save & Continue"
          button3Action={() => {
            setResetConfirmation(false);
            getFormData(getValues());
          }}
          type=""
          confirmation={true}
          buttonTitle={'Continue without saving'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className="flex gap-12">
          <div className="flex-1">
            <div className="mb-5">
              <div className="mb-2">
                {isImageUploading ? (
                  <Spinner />
                ) : (
                  <>
                    {!fileTitle ? (
                      <FileDrop onTargetClick={filePicker} onDrop={(f, e) => fileHandler(f, e)}>
                        <p className="placeholder">
                          <i className="fa fa-upload mb-2 text-blue-main" aria-hidden="true"></i>
                          <br />
                          {t('drag_and_drop', currentSelected?.langCode || selectedLanguage?.langCode) ||
                            'Drag & drop files, or'}{' '}
                          <span className="browsePopUP text-blue-main">
                            {t('browse', currentSelected?.langCode || selectedLanguage?.langCode) || 'browse'}
                          </span>
                        </p>
                        <input
                          accept="image/png,image/jpeg,video/mp4,video/x-m4v,video/*"
                          value=""
                          style={{ visibility: 'hidden', opacity: 0 }}
                          ref={inputRef}
                          type="file"
                          onChange={(e: any) => fileHandler(e.target.files, e)}
                        />
                      </FileDrop>
                    ) : fileType == 'image' ? (
                      <img className="file-drop object-cover" src={fileUrl} alt="" />
                    ) : fileType == 'video' ? (
                      <iframe className="file-drop" src={fileUrl} />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
              {errors?.['url'] && (
                <p className="mt-2 text-sm italic text-red-600">
                  {t(
                    convertToKey(errors?.['url']?.message?.toString()),
                    currentSelected?.langCode || selectedLanguage?.langCode,
                  ) || errors?.['url']?.message?.toString()}
                </p>
              )}
              <div className="flex justify-between text-right text-[12px] text-gray-subject-text">
                {fileTitle ? (
                  <span className="mr-5">
                    {t('file_size', currentSelected?.langCode || selectedLanguage?.langCode) || 'File Size : '}{' '}
                    {(+fileSize / (1024 * 1024)).toFixed(2)} MB
                  </span>
                ) : (
                  <span className="mr-5">
                    {t('file_size', currentSelected?.langCode || selectedLanguage?.langCode) || 'File Size : '} 0.00 MB
                  </span>
                )}
                <div className="text-right text-[12px] ">
                  {t('max_image_video_size', currentSelected?.langCode || selectedLanguage?.langCode) ||
                    'Max Image Size: 20MB & Max Video Size: 50MB'}
                </div>
              </div>
              {isEditPage && (
                <>
                  {isdownloading ? (
                    <Spinner />
                  ) : (
                    <button
                      disabled={err}
                      onClick={() => downloadFile()}
                      className={`my-2 rounded-md ${
                        err ? 'bg-gray-main' : 'cursor-pointer bg-blue-main'
                      } mr-[10px] px-5 py-1.5 text-sm text-white`}>
                      {t('download_file', currentSelected?.langCode || selectedLanguage?.langCode) || 'Download File'}
                    </button>
                  )}
                </>
              )}
              <button
                disabled={err}
                onClick={() => RemoveFile(false)}
                className={`my-2 rounded-md ${
                  err ? 'bg-gray-main' : 'cursor-pointer bg-red-main'
                } px-5 py-1.5 text-sm text-white`}>
                {t('remove_file', currentSelected?.langCode || selectedLanguage?.langCode) || 'Remove File'}
              </button>
            </div>
            {fileType == 'video' && fileTitle && !isImageUploading ? (
              <SrtUpload
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                edit={isEditPage}
                srtKey={videoSrtKey}
                srtValue={srtValue}
                setSrtValue={setSrtValue}
              />
            ) : (
              ''
            )}
            <div>
              <div className="mb-8 w-full">
                <Input
                  value={fileTitle}
                  label={t('file_name', currentSelected?.langCode || selectedLanguage?.langCode) || 'File Name'}
                  // control={{ ...register('shareText') }}
                  name="fileName"
                  size="large"
                  required={false}
                  disabled={true}
                  // errors={errors}
                  placeholder=""
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                />
              </div>
              <div className="mb-8 w-full">
                <Input
                  label={
                    t('alternative_text', currentSelected?.langCode || selectedLanguage?.langCode) || 'Alternative Text'
                  }
                  textarea={true}
                  control={{ ...register('alternativeText') }}
                  name="alternativeText"
                  size="large"
                  required={false}
                  errors={errors}
                  placeholder=""
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                />
              </div>
              {isEditPage && (
                <div className="mb-8 w-full">
                  <Input
                    value=""
                    label={t('upload_to', currentSelected?.langCode || selectedLanguage?.langCode) || 'Uploaded To'}
                    textarea={true}
                    name="alternativeText"
                    size="large"
                    required={false}
                    errors={errors}
                    placeholder={
                      t(uploadedTo, currentSelected?.langCode || selectedLanguage?.langCode) ||
                      uploadedTo ||
                      t('not_used_yet', currentSelected?.langCode || selectedLanguage?.langCode) ||
                      'Not Used Yet'
                    }
                    disabled={true}
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default AddMedia;
