import InputBox from 'components/common/formInput';
import { addUserFields, userDeafultValues } from 'constants/addUser.constant';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userFormValidation } from 'validations/addUser.validation';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAPIData, postAPIData, putAPIData } from 'utils/api';
import { TODO_ANY } from 'typings/common';
import dropdown from '../../assets/images/dropdown.svg';
import { InputLabelPosition } from 'enums/input.enum';
import { getRoles } from '@testing-library/react';
import { Options } from 'typings/form';
import { ToastTypes, useToast } from 'context/toast';
import { Header, Spinner, TopLayer } from 'components';
import { LanguageListsContext, SelectedLangContext, useAuth } from 'context/user';
import { useTranslation } from 'context/translation';
import DeleteModal from 'components/DeleteModal';
import LanguageDropdown from 'components/LanguageDropdown';
import { getEnglishId } from 'pages/Media/utils';

export const AddUser = () => {
  const { id } = useParams();
  const { authUser } = useAuth();
  const { langLists, setLangLists } = useContext(LanguageListsContext);

  const { selectedLanguage, setSelectedLanguage } = useContext(SelectedLangContext);
  const [currentSelectedLangId, setCurrentSelectedLangId] = useState<any>('');
  const [currentSelected, setCurrentSelected] = useState<any>({});
  const [resetConfirmation, setResetConfirmation] = useState<boolean>(false);

  const isEditPage = useMemo(() => !!id, [id]);
  const pageTitle = useMemo(() => (isEditPage ? 'Edit User' : 'Add User'), [isEditPage]);
  const titleButtonText = useMemo(() => (isEditPage ? 'Save' : 'Add'), [isEditPage]);
  const [isReset, setIsReset] = useState(false);
  const [imageId, setImageId] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [passwordResetLoader, setPasswordResetLoader] = useState<any>(false);
  const navigate = useNavigate();
  const [roles, setRoles] = useState<Options[]>([]);

  const selectedData = (e: string) => {
    for (let key of langLists) {
      if (key?.langCode === e) {
        setCurrentSelected(key);
        return;
      }
    }
  };

  const saveLogo = (val: any) => {
    setValue('imageUrl', val);
  };
  const { callToast } = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: userDeafultValues,
    resolver: yupResolver(userFormValidation),
  });

  const [isImageUploaded, setIsImagesUploaded] = useState(false);
  const { t }: any = useTranslation();
  const addEditUser = async (data: TODO_ANY) => {
    if (isImageUploaded) {
      callToast(
        ToastTypes.INFO,
        t('image_uploading', currentSelected?.langCode || selectedLanguage?.langCode) || 'Image is uploading',
      );
      return;
    }
    const payload = {
      ...data,
      // imageUrl: data.imageUrl.substr(data.imageUrl.indexOf('net/') + 4, data.imageUrl.length),
      languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id,
    };
    if (data.imageUrl) {
      payload.imageUrl = data.imageUrl.substr(data.imageUrl.indexOf('net/') + 4);
    }
    if (imageId) {
      payload.imageId = imageId;
    }
    delete payload.imageUrl;
    try {
      let res;
      if (isEditPage) {
        const obj = { url: 'user/' + id, data: payload };
        res = await putAPIData(obj);
      } else {
        const obj = { url: 'user', data: payload };
        res = await postAPIData(obj);
      }
      if (!isEditPage) {
        callToast(
          ToastTypes.SUCCESS,
          t('email_sent') || "A temporary username and password have been sent to the user's email",
        );
      }
      navigate('/users');
    } catch (err: any) {
      callToast(ToastTypes.ERROR, err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };
  const PasswordReset = async () => {
    setPasswordResetLoader(true);
    let res;
    try {
      res = await putAPIData({
        url: 'user/resetPassword/' + id,
        data: {
          languageId: currentSelected?.id || selectedLanguage?.id,
        },
      });
      setPasswordResetLoader(false);
      callToast(ToastTypes.SUCCESS, t('reset_link') || 'Password reset link has been sent');
    } catch (err: any) {
      setPasswordResetLoader(false);
      console.error(err);
    }
  };
  useEffect(() => {
    if (!(authUser && Object.keys(authUser)?.length)) {
      return;
    }
    getRoles();
    if (id) {
      getUserById();
    }
  }, [id, currentSelected?.langCode || selectedLanguage?.langCode]);

  const getRoles = async () => {
    try {
      setLoading(true);
      const res = await getAPIData({
        url: `user/roles`,
        data: { languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      const role = res?.data?.map((el: any) => {
        return { label: el?.role, value: el?.id };
      });
      setRoles(role);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const getUserById = async () => {
    try {
      setLoading(true);
      let res = await getAPIData({
        url: `user/${id}`,
        data: { languageId: getEnglishId() ?? currentSelected?.id ?? selectedLanguage?.id },
      });
      setImageId(res?.data?.imageId);
      setValue('username', res?.data?.username);
      setValue('email', res?.data?.email);
      setValue('firstName', res?.data?.firstName);
      setValue('roleId', res?.data?.roleId);
      setValue('imageUrl', res?.data?.media?.url);
      setValue('lastName', res?.data?.lastName);
    } catch (e: any) {
      callToast(ToastTypes.ERROR, e?.response?.data?.message || e?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section>
      <div className="flex justify-between">
        {' '}
        <TopLayer
          onButtonClicked={handleSubmit(addEditUser)}
          buttonText={titleButtonText}
          titleText={pageTitle}
          className="mb-8"
          onCancelClicked={() => {
            setIsReset(true);
            reset();
            navigate('/users');
          }}
          disableBtn={isImageUploaded || loading}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />{' '}
      </div>

      {resetConfirmation && (
        <DeleteModal
          showModal={setResetConfirmation}
          deleteConfirm={() => {
            reset(userDeafultValues);
            selectedData(currentSelectedLangId);
            setResetConfirmation(false);
          }}
          deleteTitle={'All changes will be lost'}
          type=""
          confirmation={true}
          buttonTitle={'Continue'}
          langCode={currentSelected?.langCode || selectedLanguage?.langCode}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(addEditUser)}>
          <div className="mt-[60px] mb-[20px]">
            <div className="flex items-center justify-between rounded-t-[5px] bg-gray-title-bg py-[14px] px-[26px]">
              <span className="text-lg font-bold text-gray-text">
                {t('user_fields', currentSelected?.langCode || selectedLanguage?.langCode) || 'User Fields'}
              </span>
            </div>
            <div className=" rounded-b-[5px] border ">
              <div className="flex">
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, currentSelected?.langCode || selectedLanguage?.langCode)?.[0]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    disable={isEditPage}
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                </div>
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, currentSelected?.langCode || selectedLanguage?.langCode)?.[1]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    disable={isEditPage}
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, currentSelected?.langCode || selectedLanguage?.langCode)?.[2]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                </div>
                <div className="w-[50%]">
                  <InputBox
                    input={addUserFields(t, currentSelected?.langCode || selectedLanguage?.langCode)?.[3]}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    control={control}
                    langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                  />
                </div>
              </div>

              <InputBox
                input={{
                  ...addUserFields(t, currentSelected?.langCode || selectedLanguage?.langCode)?.[4],
                  options: roles,
                }}
                register={register}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                control={control}
                labelPosition={InputLabelPosition.Left}
                langCode={currentSelected?.langCode || selectedLanguage?.langCode}
              />
              <div className="border-b-1 border-slate-100 px-[26px] pt-[32px] pb-[30px]">
                <InputBox
                  input={addUserFields(t, currentSelected?.langCode || selectedLanguage?.langCode)?.[5]}
                  register={register}
                  setValue={setValue}
                  setImageId={setImageId}
                  getValues={getValues}
                  errors={errors}
                  control={control}
                  disable={isEditPage}
                  block={setIsImagesUploaded}
                  isBlock={isImageUploaded}
                  langCode={currentSelected?.langCode || selectedLanguage?.langCode}
                />
              </div>
              {isEditPage && (
                <div className="px-[26px] pt-[32px] pb-[30px]">
                  <div className="flex items-center">
                    <label className="mr-[50px] block text-lg font-bold text-gray-text ">
                      {t('password_reset', currentSelected?.langCode || selectedLanguage?.langCode) || 'Password Reset'}
                    </label>
                    {passwordResetLoader ? (
                      <Spinner />
                    ) : (
                      <span
                        onClick={() => PasswordReset()}
                        className=" flex cursor-pointer items-center justify-center rounded-[5px] bg-blue-main px-[20px] py-2 text-[12px] text-white disabled:opacity-[0.5]">
                        {t('resend_link', currentSelected?.langCode || selectedLanguage?.langCode) ||
                          'Send Password Reset Link'}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </section>
  );
};
